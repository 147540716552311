<template>
  <div>
    <PDialog
      :header="title"
      :modal="true"
      class="stepper-dialog"
      :visible.sync="display"
      :contentStyle="{ overflow: 'auto' }"
      :containerStyle="{ width: '70vw' }"
    >
      <template #header>
        <div class="card px-0 border-0 w-100">
          <div>
            <h4 class="d-flex justify-content-between">
              <div>{{ title }} {{ editableItem.typeIndemniteCode }}</div>
              <PButton
                @click="cancel()"
                icon="pi pi-times"
                class="p-button-rounded p-button-outlined p-button-danger"
              />
            </h4>
            <PDivider />
          </div>
          <DStepper
            class="shadow-light"
            :steps="steps"
            :activeStepIndex="activeStepIndex"
            :readonly="false"
          />
          <div class="d-flex justify-content-between mt-2">
            <div>
              Mission sélectionnée:
              <PTag severity="light">{{
                editableItem.modeGeneration == null
                  ? "Aucun"
                  : editableItem.modeGeneration == "FULL"
                  ? "TOUS"
                  : "SPECIFIQUE"
              }}</PTag>
            </div>
            <div class="px-2">
              Agent séléctionné:
              <PTag severity="danger">{{
                entreprises.length > 0 ? entreprises[0].raisonSocial : "Aucun"
              }}</PTag>
            </div>
            <div>
              Nombre de DE séléctionnée(s):
              <PTag severity="warning">{{
                editableItem.beneficiaires != null ? editableItem.beneficiaires.length : 0
              }}</PTag>
            </div>
          </div>
        </div>
      </template>
      <div class="bg-white shadow-light rounded-3 bg-r p-3">
        <ValidationObserver ref="observer0" v-slot="{ handleSubmit }">
          <form
            ref="form0"
            method="post"
            v-if="activeStep.name == 'SELECTION_MISSION'"
            @submit.prevent="handleSubmit()"
          >
            <ValidationProvider rules="required" v-slot="{ errors }">
              <div class="row">
                <div class="col-sm-12 field" style="min-height: 200px">
                  <h6>Pour quelle mission voulez-vous effectuer cette d'opération?</h6>
                  <AsyncSearchInput
                    v-model="editableItem.mission"
                    :queryUrl="'missions'"
                    :optionLabel="'objetMission'"
                    :searchOptions="['objetMission']"
                    placeholder="Rechercher une mission"
                  />
                  <div class="mt-2 border border-primary p-2" style="border-radius: 8px">
                    <table
                      class="table table-striped"
                      v-if="editableItem.mission != null"
                    >
                      <tbody>
                        <tr>
                          <td>Objet Mission:</td>
                          <td>{{ editableItem.mission.objetMission }}</td>
                        </tr>
                        <tr>
                          <td>Ligne budgetaire:</td>
                          <td>{{ editableItem.mission.ligneBudgetaire.libelle }}</td>
                        </tr>
                        <tr>
                          <td>Date débutt:</td>
                          <td>{{ editableItem.mission.dateDu | formatDate }}</td>
                        </tr>
                        <tr>
                          <td>Date fin:</td>
                          <td>{{ editableItem.mission.dateAu | formatDate }}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <span v-for="(err, index) in errors" :key="index" class="text-danger">{{
                    err
                  }}</span>
                </div>
              </div>
            </ValidationProvider>
          </form>
        </ValidationObserver>
        <ValidationObserver
          ref="observer1"
          v-if="activeStep.name == 'PRESENCE_MISSION'"
          v-slot="{ handleSubmit }"
        >
          <form ref="form1" method="post" @submit.prevent="handleSubmit()">
            <div class="row">
              <ValidationProvider rules="" v-slot="{ errors }">
                <div class="col-sm-12 field">
                  <AvantageOrdreMissionList
                    mode="EDIT_PRESENCE_MISSION"
                    :mission="editableItem.mission"
                    :extraQueryArg="`missionId=${editableItem.mission.id}`"
                  />
                  <span v-for="(err, index) in errors" :key="index" class="text-danger">{{
                    err
                  }}</span>
                </div>
              </ValidationProvider>
            </div>
          </form>
        </ValidationObserver>
        <ValidationObserver
          ref="observer2"
          v-if="activeStep.name == 'AVANTAGE_ORDRE_MISSION'"
          v-slot="{ handleSubmit }"
        >
          <form ref="form2" method="post" @submit.prevent="handleSubmit()">
            <div class="row">
              <div class="col-sm-12 field">
                <AvantageOrdreMissionList
                  mode="EDIT_AVANTAGE_ORDRE_MISSION"
                  :mission="editableItem.mission"
                  :extraQueryArg="`missionId=${editableItem.mission.id}`"
                />
                <span v-for="(err, index) in errors" :key="index" class="text-danger">{{
                  err
                }}</span>
              </div>
            </div>
          </form>
        </ValidationObserver>
      </div>

      <template #footer>
        <PButton
          label="Quitter"
          icon="pi pi-times"
          @click="cancel"
          class="p-button-text p-button-danger"
        />
        <PButton
          label="Précédent"
          icon="pi pi-chevron-left"
          :disabled="activeStepIndex <= 0"
          class="p-button-outlined"
          @click.prevent="goToPrevious"
        />
        <PButton
          label="Suivant"
          icon="pi  pi-chevron-right"
          v-if="activeStepIndex < steps.length - 1"
          @click.prevent="goToNext"
        />
        <PButton
          class="p-button p-button-primary"
          label="Valider"
          icon="pi pi-clock"
          v-if="activeStepIndex == steps.length - 1"
          @click="save"
          autofocus
        />
      </template>
    </PDialog>
  </div>
</template>

<script>
import { paginatorMixin } from "@/mixins/paginator-mixin";
import { mapActions } from "vuex";
import AsyncSearchInput from "../../../../common/AsyncSearchInput.vue";
import DStepper from "../../../../common/DStepper.vue";
import SearchableItemParticipantPayementSelector from "../../../../common/SearchableItemParticipantPayementSelector.vue";
import SearchableItemEntrepriseSelector from "../../../../common/SearchableItemEntrepriseSelector.vue";
import AvantageOrdreMissionList from "../../../../../views/espace/common/mission/AvantageOrdreMissionList.vue";

export default {
  components: {
    AsyncSearchInput,
    DStepper,
    SearchableItemParticipantPayementSelector,
    SearchableItemEntrepriseSelector,
    AvantageOrdreMissionList,
  },
  props: {
    title: {
      type: String,
      default: "Générer un payement d'indemnité",
    },
    size: {
      type: String,
      default: "md",
    },
    item: {
      type: Object,
      default: () => ({}),
    },
  },
  mixins: [paginatorMixin],
  data() {
    return {
      display: false,
      activeStepIndex: 0,
      activeTab: 0,
      beneficiaires: [],
      entreprises: [],
      editableItem: {
        modeGeneration: null,
        entreprise: null,
        beneficiares: [],
      },
    };
  },
  watch: {
    item: {
      handler() {
        this.editableItem = { ...this.item };
      },
      deep: true,
    },
  },
  computed: {
    referentielName() {
      return "demandeur";
    },
    stepCount() {
      return this.editableItem.modeGeneration != "ALL" ? 1 : 2;
    },
    emptyReferentielItem() {
      return {
        titre: null,
        description: null,
        grille: null,
        code: null,
        activite: null,
      };
    },
    steps() {
      let steps = [
        {
          name: "SELECTION_MISSION",
          label: "CHOIX DE LA MISSION",
          subtitle: "Choix de la mission",
          to: "#choixMission",
          completed: this.activeStepIndex >= 0,
          command: () => {},
        },
        {
          name: "PRESENCE_MISSION",
          label: "PRESENCE MISSION",
          subtitle: "Renseigner la présence à la mission",
          to: "#presence-mission",
          completed: this.activeStepIndex >= 1,
          command: () => {},
        },
        {
          name: "AVANTAGE_ORDRE_MISSION",
          label: "AVANTAGE ORDRE MISSION",
          subtitle: "Renseignement des avantages",
          to: "#avantageMission",
          completed: this.activeStepIndex >= 2,
          command: () => {},
        },
      ];

      return steps;
    },
    activeStep() {
      return this.steps[this.activeStepIndex];
    },
    tableFields() {
      let fields = [
        "#",
        "index",
        { key: "beneficiaireNom", label: "Nom" },
        { key: "beneficiairePrenom", label: "Prénom" },
        { key: "beneficiairePhone", label: "Téléphone" },
        { key: "statutPayement", label: "Statut Pay." },
        { key: "actions", label: "Actions" },
      ];
      return fields;
    },
    entrepriseTableFields() {
      let fields = [
        "#",
        "index",
        { key: "ifu", label: "IFU" },
        { key: "raisonSocial", label: "Dénomination" },
        { key: "phone", label: "Téléphone" },
        { key: "commune.nom", label: "Commune" },
        { key: "departement.nom", label: "Département" },
      ];
      return fields;
    },
  },
  methods: {
    ...mapActions({}),

    show() {
      this.display = true;
    },
    hide() {
      this.display = false;
    },
    cancel() {
      this.hide();
      this.$emit("canceled");
    },
    presenceChange(item) {
      // this.create;
    },
    handleInput(e) {
      this.editableItem[e.target.name] = e.target.value;
      this.$emit("input", this.editableItem);
    },
    async goToNext() {
      if (!(await this.isCompleted(this.activeStepIndex))) return;
      this.activeStepIndex +=
        this.activeStepIndex < this.steps.length - 1 ? this.stepCount : 0;
    },
    async goToPrevious() {
      this.activeStepIndex -= this.activeStepIndex > 0 ? this.stepCount : 0;
    },
    async isCompleted(step) {
      const valid = await this.$refs["observer" + step].validate();
      return valid;
    },
    extraQueryArgsEntrepriseParamBuilder() {
      return ``;
    },
    extraQueryArgsParamBuilder() {
      let queryParam = `typeIndemniteCode=${this.editableItem.typeIndemniteCode}`;
      if (this.entreprises.length > 0) {
        queryParam += `&entrepriseId=${this.entreprises[0].id}`;
      }
      return queryParam;
    },
    async save() {
      if (!(await this.isCompleted(this.steps.length - 1))) return;

      this.$emit("save", {
        ...this.editableItem,
        entreprise: this.entreprises[0],
      });
      this.editableItem = {};
      this.hide();
    },
  },
};
</script>

<style>
.field-checkbox,
.field-radiobutton {
  display: flex;
  justify-items: center;
}
.stepper-dialog .p-dialog-header-icons {
  display: none;
}

.stepper-dialog .p-dialog-content {
  background: #faf4fa;
}

.stepper-dialog .p-dialog-footer {
  box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.2), 0px -1px 1px rgba(0, 0, 0, 0.2);
}

.stepper-dialog .p-dialog-header {
  box-shadow: 1px 2px 0px 0px rgba(0, 0, 0, 0.5);
}

.shadow-light {
  box-shadow: 0 0.5rem 1rem rgb(0 0 0 / 3%) !important;
}

.msg-cloture.p-message .p-message-wrapper {
  padding: 0.5rem 1.2rem;
}
</style>
