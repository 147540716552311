<template>
  <div>
    <div class="row">
      <div class="col-sm-12 field">
        <SearchableItemParticipantPayementSelector
          ref="searchable"
          :listTitle="'Liste des bénéficiaires'"
          :referentielName="referentielName"
          :tableFields="tableFields"
          :extraQueryArgsParamBuilder="extraQueryArgsParamBuilder"
          v-model="editableItem.beneficiaires"
          :selectable="false"
        >
          <template #listTitle>
            <h6>Liste des participants</h6>
          </template>
          <template #listDescription> </template>
          <template #rowDetails="{ row }"> </template>
          <template #actions="{ data }"> </template>
        </SearchableItemParticipantPayementSelector>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import AsyncSearchInput from "../../../../../common/AsyncSearchInput.vue";
import SearchableItemParticipantPayementSelector from "../../../../../common/SearchableItemParticipantPayementSelector.vue";

export default {
  components: {
    AsyncSearchInput,
    SearchableItemParticipantPayementSelector,
  },
  props: {
    extraQueryArg: {
      type: String,
      default: "",
    },
    title: {
      type: String,
      default: "Générer un payement d'indemnité",
    },
    size: {
      type: String,
      default: "md",
    },
    // item: {
    //   type: Object,
    //   default: () => ({}),
    // },
  },
  data() {
    return {
      display: false,
      beneficiaires: [],
      editableItem: {
        modeGeneration: null,
        entreprise: null,
        beneficiaires: [],
      },
    };
  },
  watch: {
    // item: {
    //   handler() {
    //     this.editableItem = { ...this.item };
    //   },
    //   deep: true,
    // },
  },
  computed: {
    referentielName() {
      return "demandeur";
    },
    tableFields() {
      let fields = [
        "#",
        "index",
        { key: "beneficiaireNom", label: "Nom" },
        { key: "beneficiairePrenom", label: "Prénom" },
        { key: "beneficiairePhone", label: "Téléphone" },
        { key: "beneficiaireSexe", label: "Sexe" },
        { key: "statutPayement", label: "Statut Pay." },
        { key: "motifPayement", label: "Motif Pay." },
        { key: "actions", label: "Actions" },
      ];
      return fields;
    },
  },
  methods: {
    ...mapActions({}),
    forceReload() {
      this.$refs.searchable.reloadData();
    },
    getSelectedBeneficiaires() {
      return this.editableItem.beneficiaires;
    },
    extraQueryArgsParamBuilder() {
      //   let queryParam = `typeIndemniteCode=${this.editableItem.typeIndemniteCode}`;
      //   if (this.entreprises.length > 0) {
      //     queryParam += `&entrepriseId=${this.entreprises[0].id}`;
      //   }
      //   if (this.activePlacement != null) {
      //     queryParam += `&placementId=${this.activePlacement.id}`;
      //   }

      return this.extraQueryArg;
    },
  },
};
</script>

<style>
.field-checkbox,
.field-radiobutton {
  display: flex;
  justify-items: center;
}
.stepper-dialog .p-dialog-header-icons {
  display: none;
}

.stepper-dialog .p-dialog-content {
  background: #faf4fa;
}

.stepper-dialog .p-dialog-footer {
  box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.2), 0px -1px 1px rgba(0, 0, 0, 0.2);
}

.stepper-dialog .p-dialog-header {
  box-shadow: 1px 2px 0px 0px rgba(0, 0, 0, 0.5);
}

.shadow-light {
  box-shadow: 0 0.5rem 1rem rgb(0 0 0 / 3%) !important;
}

.msg-cloture.p-message .p-message-wrapper {
  padding: 0.5rem 1.2rem;
}
</style>
