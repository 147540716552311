<template>
  <div class="vue-csv-uploader">
    <div class="form">
      <div class="row wrap">
        <div class="col-md-12">
          <div class="vue-csv-uploader-part-one">
            <div v-if="1 == 0" class="form-check form-group">
              <input
                :class="checkboxClass"
                type="checkbox"
                id="hasHeaders"
                v-model="hasHeaders"
              />
              <label class="form-check-label" for="hasHeaders"> File Has Headers </label>
            </div>
            <div class="file-drop-area">
              <input
                style="display: none"
                ref="csv"
                type="file"
                @change="fileSelected"
                :class="inputClass"
                name="csv"
              />
              <button
                class="btn btn-default"
                v-if="!selectedFile"
                @click="$refs.csv.click()"
              >
                <i class="pi pi-cloud-upload" style="font-size: 2rem"></i>
              </button>
              <span v-if="!selectedFile" class="grey--text"
                >Cliquez pour sélectonner un fichier</span
              >
              <span v-else></span>
              <PChip v-if="selectedFile" @input="clearSelectedFile">
                <i class="pi pi-file-excel"></i>
                <span class="mr-3 px-2">{{ selectedFile.name }}</span>
                <i @click.prevent="clearSelectedFile" class="pi pi-times-circle"></i>
              </PChip>
            </div>
          </div>
        </div>
        <div class="cold-md-12">
          <div class="vue-csv-uploader-part-tw0">
            <div class="vue-csv-mapping" v-if="sample">
              <table :class="tableClass" class="table table-csv" style="width: 100%">
                <thead>
                  <tr>
                    <th>Champ</th>
                    <th>Colonne Excel</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(field, key) in fieldsToMap" :key="key">
                    <td>{{ field.label }}</td>
                    <td>
                      <PDropdown
                        optionValue="key"
                        optionLabel="value"
                        :options="
                          firstRow.map((item, i) => {
                            return { key: i, value: item };
                          })
                        "
                        placeholder="Select la colonne"
                        v-model="map[field.key]"
                      >
                      </PDropdown>
                    </td>
                  </tr>
                </tbody>
              </table>

              <div class="form-group" v-if="url">
                <v-btn :class="buttonClass" round @click.prevent="submit">
                  {{ submitBtnText }}
                </v-btn>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import axios from "axios";
import Papa from "papaparse";

export default {
  props: {
    value: Array,
    url: {
      type: String,
    },
    keyValueMapFields: {
      required: true,
    },
    mapFields: {
      required: true,
    },
    callback: {
      type: Function,
      default: () => {},
    },
    catch: {
      type: Function,
      default: () => {},
    },
    finally: {
      type: Function,
      default: () => {},
    },
    loadBtnText: {
      type: String,
      default: "Submit",
    },
    submitBtnText: {
      type: String,
      default: "Submit",
    },
    tableClass: {
      type: String,
      default: "table",
    },
    checkboxClass: {
      type: String,
      default: "form-check-input",
    },
    buttonClass: {
      type: String,
      default: "btn btn-primary",
    },
    inputClass: {
      type: String,
      default: "form-control-file",
    },
  },

  data: () => ({
    form: {
      csv: null,
    },
    fieldsToMap: [],
    map: {},
    hasHeaders: true,
    csv: null,
    sample: null,
    selectedFile: null,
  }),

  created() {
    if (_.isArray(this.mapFields)) {
      this.fieldsToMap = _.map(this.mapFields, (item) => {
        return {
          key: item,
          label: item,
        };
      });
    } else {
      this.fieldsToMap = _.map(this.mapFields, (label, key) => {
        return {
          key: key,
          label: label,
        };
      });
    }
  },

  methods: {
    submit() {
      const _this = this;
      this.form.csv = this.buildMappedCsv();

      let mappedData = this.form.csv.map((item) => {
        let mappedItem = {};
        for (let key in this.keyValueMapFields) {
          mappedItem[key] = item[this.keyValueMapFields[key]];
        }
        return mappedItem;
      });

      const keysCount = this.mapFields.length;
      this.$emit(
        "input",
        mappedData.filter((el) => Object.keys(el).length == keysCount)
      );

      if (this.url) {
        axios
          .post(this.url, this.form)
          .then((response) => {
            _this.callback(response);
          })
          .catch((response) => {
            _this.catch(response);
          })
          .finally((response) => {
            _this.finally(response);
          });
      }
    },
    buildMappedCsv() {
      const _this = this;

      let csv = this.hasHeaders ? _.drop(this.csv) : this.csv;
      return _.map(csv, (row) => {
        let newRow = {};
        _.forEach(_this.map, (column, field) => {
          _.set(newRow, field, _.get(row, column));
        });

        return newRow;
      });
    },
    fileSelected() {
      this.selectedFile = this.$refs.csv.files[0];
      this.getSample();
    },
    clearSelectedFile() {
      this.selectedFile = null;
      (this.csv = null), (this.form.csv = null);
      this.sample = null;
      this.$emit("cleared");
    },
    getSample() {
      const _this = this;
      this.readFile((output) => {
        _this.sample = _.get(Papa.parse(output, { preview: 2 }), "data");
        _this.csv = _.get(Papa.parse(output), "data");
      });
    },
    readFile(callback) {
      let file = this.$refs.csv.files[0];

      if (file) {
        let reader = new FileReader();
        reader.readAsText(file, "UTF-8");
        reader.onload = function (evt) {
          callback(evt.target.result);
        };
        reader.onerror = function () {};
      }
    },
  },
  watch: {
    map: {
      handler: function (newVal) {
        if (!this.url) {
          var hasAllKeys = [];

          if (_.isArray(this.mapFields)) {
            hasAllKeys = this.mapFields.every(function (item) {
              return newVal.hasOwnProperty(item);
            });
          } else {
            hasAllKeys = Object.keys(this.mapFields).every(function (item) {
              return newVal.hasOwnProperty(item);
            });
          }

          if (hasAllKeys) {
            this.submit();
          }
        }
      },
      deep: true,
    },
  },
  computed: {
    firstRow() {
      return _.get(this, "sample.0");
    },
  },
};
</script>

<style scoped>
.vue-csv-uploader {
  background: white;
}
.table-csv td,
.table-csv th {
  padding: 5px 30px;
}

.file-drop-area {
  height: 100px;
  background: white;
  border: 1px dashed #aaa;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
</style>
