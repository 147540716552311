<template>
  <div>
    <PDialog
      class="stepper-dialog"
      :header="'Payement des prestataires'"
      :modal="true"
      :visible.sync="display"
      :contentStyle="{ overflow: 'auto' }"
      :containerStyle="{ width: '70vw' }"
    >
      <template #header>
        <div class="card px-0 border-0 mb-2 w-100">
          <div>
            <h4 class="d-flex justify-content-between">
              <div>
                {{ title || "Payement des prestataires" }}
              </div>
              <PButton
                @click="cancel()"
                icon="pi pi-times"
                class="p-button-rounded p-button-outlined p-button-danger"
              />
            </h4>
            <PDivider />
          </div>
          <DStepper
            class="shadow-light"
            :steps="steps"
            :activeStepIndex="activeStepIndex"
            :readonly="false"
          />
          <!-- <PMessage class="mb-0 p-0 msg-cloture" v-if="activite.statusActivite == 'CLOTUREE'" severity="error">Ce rapport est déjà clôturé. Aucune modification de son contenu ne sera plus pris en compte</PMessage> -->
        </div>
      </template>

      <div class="bg-white shadow-light rounded-3 bg-r p-3">
        <ValidationObserver ref="observer_PRESTATION_INFO" v-slot="{ handleSubmit }">
          <form
            ref="form0"
            method="post"
            v-if="activeStep.name == 'PRESTATION_INFO'"
            @submit.prevent="handleSubmit()"
          >
            <ValidationProvider rules="required" v-slot="{ errors }">
              <div class="row grid-md">
                <div class="col-sm-12 field">
                  <ValidationProvider rules="required" v-slot="{ errors }">
                    <div class="form-floating mb-3">
                      <input
                        type="text"
                        v-uppercase
                        id="titre"
                        class="form-control"
                        name="titre"
                        v-model="editableItem.titre"
                      />
                      <label for="titre">Intitule de la prestation</label>
                      <span
                        v-for="(err, index) in errors"
                        :key="index"
                        class="text-danger"
                        >{{ err }}</span
                      >
                    </div>
                  </ValidationProvider>
                </div>
                <div class="col-sm-6 field">
                  <ValidationProvider rules="required" v-slot="{ errors }">
                    <div>Date début</div>
                    <p-calendar
                      class="col-12 md:col-12 mb-3"
                      :showIcon="true"
                      size="sm"
                      locale="fr"
                      name="dateDu"
                      :manualInput="false"
                      v-model="editableItem.dateDu"
                    />
                    <span
                      v-for="(err, index) in errors"
                      :key="index"
                      class="text-danger"
                      >{{ err }}</span
                    >
                  </ValidationProvider>
                </div>
                <div class="col-sm-6 field">
                  <ValidationProvider rules="required" v-slot="{ errors }">
                    <div>Date fin</div>
                    <p-calendar
                      class="col-12 md:col-12 mb-3"
                      :showIcon="true"
                      size="sm"
                      locale="fr"
                      name="dateAu"
                      :manualInput="false"
                      v-model="editableItem.dateAu"
                    />
                    <span
                      v-for="(err, index) in errors"
                      :key="index"
                      class="text-danger"
                      >{{ err }}</span
                    >
                  </ValidationProvider>
                </div>
                <div class="col-sm-12 field mt-3">
                  <ValidationProvider rules="" v-slot="{ errors }">
                    <div class="form-floating mb-3">
                      <textarea
                        type="text"
                        rows="10"
                        style="height: 120px"
                        id="observation"
                        class="form-control"
                        name="observation"
                        v-model="editableItem.observation"
                      />
                      <label for="observation">Observation</label>
                      <span
                        v-for="(err, index) in errors"
                        :key="index"
                        class="text-danger"
                        >{{ err }}</span
                      >
                    </div>
                  </ValidationProvider>
                </div>
              </div>
            </ValidationProvider>
          </form>
        </ValidationObserver>
        <ValidationObserver
          ref="observer_SELECTION_PARTICIPANT"
          v-if="activeStep.name == 'SELECTION_PARTICIPANT'"
          v-slot="{ handleSubmit }"
        >
          <form ref="form2" method="post" @submit.prevent="handleSubmit()">
            <div class="card mt-4 border-0">
              <div class="card-title">
                <div class="d-flex flex-row justify-content-between align-items-center">
                  <div class="mr-auto pb-3">
                    <h4>Importer la liste des prestataires depuis fichier CSV/Excel</h4>
                  </div>
                </div>
              </div>
              <div class="card-body">
                <ValidationProvider rules="required" v-slot="{ errors }">
                  <CsvImporter
                    @cleared="clear"
                    loadBtnText="Charger le fichier"
                    :key-value-map-fields="mapFields"
                    v-model="importedData"
                    :map-fields="getFieldsMap"
                  ></CsvImporter>
                </ValidationProvider>
                <div class="pt-2">{{ getFieldsMap }}</div>
              </div>
            </div>
          </form>
        </ValidationObserver>

        <ValidationObserver
          ref="observer_PIECE_JUSTIFICATIVE"
          v-if="activeStep.name == 'PIECE_JUSTIFICATIVE'"
          v-slot="{ handleSubmit }"
        >
          <div class="card mt border-0">
            <form ref="form" method="post" @submit.prevent="handleSubmit()">
              <template>
                <div class="col-sm-12 field">
                  <div class="form-floating mb-3">
                    <ValidationProvider rules="required" v-slot="{ errors }">
                      <label for="libelle">Pièce justificative</label>
                      <div>
                        <FilePondUploader
                          ref="fileUploader"
                          class="col-12 md:col-12"
                          v-model="editableItem.pieceJointeUrl"
                          @save="fileSaved"
                          :autoResize="true"
                          rows="8"
                        />
                      </div>
                      <span
                        v-for="(err, index) in errors"
                        :key="index"
                        class="text-danger"
                        >{{ err }}</span
                      >
                    </ValidationProvider>
                  </div>
                </div>
              </template>
            </form>
          </div>
        </ValidationObserver>
      </div>
      <template #footer>
        <PButton
          label="Quitter"
          icon="pi pi-times"
          @click="cancel"
          class="p-button-text"
        />
        <PButton
          label="Précédent"
          icon="pi pi-chevron-left"
          :disabled="activeStepIndex <= 0"
          class="p-button-outlined"
          @click.prevent="goToPrevious"
        />
        <PButton
          label="Suivant"
          :disabled="
            activeStep.name == 'SELECTION_PARTICIPANT' && importedData.length == 0
          "
          icon="pi  pi-chevron-right"
          v-if="activeStepIndex < steps.length - 1"
          @click.prevent="goToNext"
        />
        <PButton
          class="p-button p-button-primary"
          label="Valider"
          icon="pi pi-clock"
          v-if="activeStepIndex == steps.length - 1"
          @click="save"
          autofocus
        />
      </template>
    </PDialog>
  </div>
</template>

<script>
import { paginatorMixin } from "@/mixins/paginator-mixin";
import CsvImporter from "../../../../uploader/CsvImporter.vue";
import DStepper from "../../../../common/DStepper.vue";
import AsyncSearchInput from "../../../../common/AsyncSearchInput.vue";
import FilePondUploader from "../../../../uploader/FilePondUploader.vue";

export default {
  components: {
    CsvImporter,
    DStepper,
    AsyncSearchInput,
    FilePondUploader,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    size: {
      type: String,
      default: "md",
    },
    item: {
      type: Object,
      default: () => ({}),
    },
  },
  mixins: [paginatorMixin],
  data() {
    return {
      activeStepIndex: 0,
      display: false,
      editableItem: {},
      importedData: [],
      filterOptions: [],
      mapFields: {
        nom: "Nom",
        prenom: "Prénom",
        phone: "Téléphone",
        nombreAbsence: "Nombre d'absence",
      },
      existingContacts: [],
      error: false,
    };
  },
  watch: {
    item() {
      this.editableItem = { ...this.item, beneficiaires: [] };
    },
  },
  created() {},
  computed: {
    getFieldsMap() {
      let fields = [];
      for (let key in this.mapFields) {
        fields.push(this.mapFields[key]);
      }
      return fields;
    },
    stepCount() {
      return 1;
    },
    steps() {
      let steps = [
        {
          name: "PRESTATION_INFO",
          label: "INFO SUR LA PRESTATION",
          subtitle: "Informations sur la prestation",
          to: "#info-prestation",
          completed: this.activeStepIndex >= 0,
          command: () => {},
        },
      ];

      steps.push({
        name: "SELECTION_PARTICIPANT",
        label: "SELECTION PARTICIPANTS",
        subtitle: "Joindres fichier excel(csv) des participants",
        to: "#selection-participant",
        completed: this.activeStepIndex >= 1,
        command: () => {},
      });

      steps = [
        ...steps,
        {
          name: "PIECE_JUSTIFICATIVE",
          label: "PIECES  JUSTIFICATIVE",
          subtitle: "Joindres les pièces justificatives",
          to: "#piece-justificative",
          completed: this.activeStepIndex >= 2,
          command: () => {},
        },
      ];

      return steps;
    },
    activeStep() {
      return this.steps[this.activeStepIndex];
    },
    tableFields() {
      return [
        "#",
        "index",
        { key: "participant.nom", label: "Nom" },
        { key: "participant.prenom", label: "Prénom" },
        { key: "participant.phone", label: "Téléphone" },
        { key: "participant.commune.nom", label: "Commune" },
        { key: "programme.nom", label: "Programme" },
      ];
    },
  },
  methods: {
    clear() {
      this.importedData = [];
    },
    resetContent() {
      this.clear();
    },
    show() {
      this.resetContent();
      this.display = true;
    },
    hide() {
      this.display = false;
    },
    ok() {
      this.editableItem = {};
    },
    fileSaved(fileInfo) {
      this.editableItem.pieceJointeUrl = fileInfo[0];
    },
    cancel() {
      this.hide();
      this.$emit("canceled");
    },
    resetContent() {
      this.activeStepIndex = 0;
    },
    async goToNext() {
      // if (!(await this.isCompleted(this.activeStepIndex))) return;
      this.activeStepIndex +=
        this.activeStepIndex < this.steps.length - 1 ? this.stepCount : 0;
    },
    async goToPrevious() {
      this.activeStepIndex -= this.activeStepIndex > 0 ? this.stepCount : 0;
    },
    async isCompleted(step) {
      const valid = await this.$refs["observer_" + this.activeStep.name].validate();
      return valid;
    },
    async save() {
      const { titre, dateDu, dateAu, observation, pieceJointeUrl } = this.editableItem;
      this.$emit("save", {
        ...this.editableItem,
        beneficiaires: this.importedData,
        prestationInfo: {
          titre,
          dateAu,
          dateDu,
          pieceJointeUrl,
          observation,
        },
      });
      this.editableItem = {};
      this.hide();
    },
  },
};
</script>

<style>
.field-checkbox,
.field-radiobutton {
  display: flex;
  justify-items: center;
}
.stepper-dialog .p-dialog-header-icons {
  display: none;
}

.stepper-dialog .p-dialog-content {
  background: #faf4fa;
}

.stepper-dialog .p-dialog-footer {
  box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.2), 0px -1px 1px rgba(0, 0, 0, 0.2);
}

.stepper-dialog .p-dialog-header {
  box-shadow: 1px 2px 0px 0px rgba(0, 0, 0, 0.5);
}

.shadow-light {
  box-shadow: 0 0.5rem 1rem rgb(0 0 0 / 3%) !important;
}

.msg-cloture.p-message .p-message-wrapper {
  padding: 0.5rem 1.2rem;
}
</style>
