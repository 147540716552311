<template>
  <div>
    <div>Liste des placements de l'entreprise {{ entreprise.raisonSocial }}</div>
    <p-progress-bar v-if="loading" style="height: 0.33em" mode="indeterminate" />

    <ul class="list-group">
      <li class="list-group-item cursor-pointer" v-for="(plc, i) in placements" :key="i">
        <div class="d-flex justify-content-between">
          <div>
            <input
              class="form-check-input me-1"
              type="radio"
              :value="plc"
              v-model="activePlacement"
              :name="`placementRadio`"
              :id="`placementRadio${i}`"
              @change="selectionChanged(plc)"
            />
            <label class="form-check-label stretched-link" :for="`placementRadio${i}`">{{
              plc.offre.intitule
            }}</label>
            <div class="d-flex w-100 justify-content-between">
              <h5 class="mb-1">
                {{ plc.offre.intitule }}
              </h5>
              <small></small>
            </div>
            <p class="mb-1">{{ plc.offre.employeur.nomDirigeant }}.</p>
            <small>{{ plc.offre.employeur.commune.nom }}</small>
          </div>
          <div>
            <span class="text-muted">{{ plc.entreprise.raisonSocial }}</span>
          </div>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  props: {
    value: {
      type: Object,
      default: null,
    },
    entreprise: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      placements: [],
      activePlacement: null,
      loading: false,
    };
  },
  created() {
    this.loadData();
  },
  methods: {
    ...mapActions({
      fetchPlacements: "placement/fetchPlacements",
    }),
    selectionChanged() {
      console.log(this.activePlacement);
      this.$emit("input", this.activePlacement);
    },
    loadData() {
      this.fetchPlacements({
        extraQueryArg: `entreprise.id=${this.entreprise.id}`,
      }).then((data) => {
        let { content } = data;
        this.placements = content.map((el) => ({
          ...el,
          selected: false,
        }));
      });
    },
  },
};
</script>

<style></style>
