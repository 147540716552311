/* eslint-disable vue/no-unused-components */
<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-sm-12 col-md-12">
        <PTabView :activeIndex.sync="activeTab" class="mt-5">
          <PTabPanel header="Liste des payements">
            <!-- <div class="row fw-bold bg-white mt-3">
      <div class="col shadow-1">SANS ABSENCE: {{ stat.regulierCount }}</div>
      <div class="col shadow-1">AVEC ABSENCE: {{ stat.semiRegulierCount }}</div>
      <div class="col shadow-1">
        ABANDON: {{ stat.totalCount - stat.semiRegulierCount - stat.regulierCount }}
      </div>
      <div class="col shadow-1">TOTAL: {{ stat.totalCount }}</div>
    </div> -->
            <EntreprisePayementList />
          </PTabPanel>
        </PTabView>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { paginatorMixin } from "@/mixins/paginator-mixin";
import { authMixin } from "@/mixins/auth-mixin";

import EntreprisePayementList from "./EntreprisePayementList.vue";

export default {
  props: {},
  components: {
    EntreprisePayementList,
  },
  mixins: [paginatorMixin, authMixin],
  data() {
    return {
      activeTab: 0,
    };
  },
  created() {},
  watch: {},
  computed: {
    ...mapGetters({}),
  },
  methods: {
    ...mapActions({}),
  },
};
</script>

<style>
.ellipsis {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
</style>
