<template>
  <div>
    <PDialog
      :header="title"
      :modal="true"
      class="stepper-dialog"
      :visible.sync="display"
      :contentStyle="{ overflow: 'auto' }"
      :containerStyle="{ width: '90vw' }"
    >
      <template #header>
        <div class="card px-0 border-0 w-100">
          <div>
            <h4 class="d-flex justify-content-between">
              <div>
                {{ title }}
              </div>
              <PButton
                @click="cancel()"
                icon="pi pi-times"
                class="p-button-rounded p-button-outlined p-button-danger"
              />
            </h4>
            <PDivider />
          </div>
          <!-- <DStepper
            class="shadow-light"
            :steps="steps"
            :activeStepIndex="activeStepIndex"
            :readonly="false"
          /> -->
          <!-- <div class="d-flex justify-content-between mt-2">
            <div>
              Mode de génération séléctionné(s):
              <PTag severity="light">{{
                editableItem.modeGeneration == null
                  ? "Aucun"
                  : editableItem.modeGeneration == "FULL"
                  ? "TOUS"
                  : "SPECIFIQUE"
              }}</PTag>
            </div>
            <div class="px-2">
              Entreprise séléctionnée:
              <PTag severity="danger">{{
                entreprises.length > 0 ? entreprises[0].raisonSocial : "Aucun"
              }}</PTag>
            </div>
            <div>
              Nombre de DE séléctionnée(s):
              <PTag severity="warning">{{
                editableItem.beneficiaires != null ? editableItem.beneficiaires.length : 0
              }}</PTag>
            </div>
          </div> -->
          <div class="d-flex justify-content-">
            <div class="px-2">
              Entreprise séléctionnée:
              <PTag severity="danger" v-if="editableItem.entreprise != null">
                {{ editableItem.entreprise.raisonSocial }}</PTag
              >
            </div>
            <div class="px-2 pl-5 ml-5">
              Type d'indemnité séléctionné:
              <PTag severity="warning" v-if="activeTypeIndemnite != null">{{
                activeTypeIndemnite || "?"
              }}</PTag>
            </div>
          </div>
        </div>
        <p-progress-bar v-if="generating" style="height: 0.33em" mode="indeterminate" />
      </template>

      <div class="d-flex">
        <div class="h-100" style="width: 350px">
          <DStepper
            class="shadow-light"
            :steps="steps"
            orientation="vertical"
            :activeStepIndex="activeStepIndex"
            :readonly="false"
          />
        </div>
        <div class="px-3" style="width: calc(100%)">
          <div class="bg-white shadow-light rounded-3 bg-r p-3">
            <ValidationObserver
              ref="observer_SELECTION_TYPE_INDEMNITE"
              v-if="activeStep.name == 'SELECTION_TYPE_INDEMNITE'"
              v-slot="{ handleSubmit }"
            >
              <form
                ref="form_SELECTION_TYPE_INDEMNITE"
                method="post"
                @submit.prevent="handleSubmit()"
              >
                <div class="row">
                  <ValidationProvider rules="required" v-slot="{ errors }">
                    <div class="col-sm-12 field">
                      <TypeIndemniteSelector v-model="activeTypeIndemnite" />
                      <span
                        v-for="(err, index) in errors"
                        :key="index"
                        class="text-danger"
                        >{{ err }}</span
                      >
                    </div>
                  </ValidationProvider>
                </div>
              </form>
            </ValidationObserver>

            <ValidationObserver
              ref="observer_GENERATION_PAYEMENT"
              v-slot="{ handleSubmit }"
            >
              <form
                ref="form_GENERATION_PAYEMENT"
                method="post"
                v-if="activeStep.name == 'GENERATION_PAYEMENT'"
                @submit.prevent="handleSubmit()"
              >
                <div class="row">
                  <ValidationProvider rules="required" v-slot="{ errors }">
                    <div class="col-sm-12 field">
                      <PointPresenceParEntreprisePayement
                        ref="ppPayement"
                        :entreprise="editableItem.entreprise"
                        v-if="activeTypeIndemnite == 'FRAIS_STAGE'"
                        @generate="handleGeneratePayement"
                      />

                      <BeneficiairePayementParEntreprise
                        v-else
                        :entreprise="editableItem.entreprise"
                        :typeIndemniteCode="activeTypeIndemnite"
                        :extraQueryArg="`typeIndemniteCode=${activeTypeIndemnite}&entrepriseId=${editableItem.entreprise.id}`"
                        v-model="editableItem.beneficiaires"
                      />

                      <span
                        v-for="(err, index) in errors"
                        :key="index"
                        class="text-danger"
                        >{{ err }}</span
                      >
                    </div>
                  </ValidationProvider>
                </div>
              </form>
            </ValidationObserver>
            <!-- 

            <ValidationObserver
              ref="observer_SELECTION_POINT_PRESENCE"
              v-slot="{ handleSubmit }"
            >
              <form
                ref="form_SELECTION_POINT_PRESENCE"
                method="post"
                v-if="activeStep.name == 'SELECTION_POINT_PRESENCE'"
                @submit.prevent="handleSubmit()"
              >
                <div class="row">
                  <ValidationProvider rules="" v-slot="{ errors }">
                    <div class="col-sm-12 field">
                      <PointPresenceParEntreprisePayement
                        ref="ppPayement"
                        :placement="activePlacement"
                        @generate="handleGeneratePayement"
                      />

                      <span
                        v-for="(err, index) in errors"
                        :key="index"
                        class="text-danger"
                        >{{ err }}</span
                      >
                    </div>
                  </ValidationProvider>
                </div>
              </form>
            </ValidationObserver>

            <ValidationObserver
              ref="observer_SELECTION_BENEFICIAIRE"
              v-if="activeStep.name == 'SELECTION_BENEFICIAIRE'"
              v-slot="{ handleSubmit }"
            >
              <form
                ref="form_SELECTION_BENEFICIAIRE"
                method="post"
                @submit.prevent="handleSubmit()"
              >
                <div class="row">
                  <div class="col-sm-12 field">
                    <PriseServicePayement
                      ref="priseServicePayement"
                      :placement="activePlacement"
                      :typeIndemniteCode="editableItem.typeIndemniteCode"
                      @generate="handleGeneratePayement"
                    />
                    <span
                      v-for="(err, index) in errors"
                      :key="index"
                      class="text-danger"
                      >{{ err }}</span
                    >
                  </div>
                </div>
              </form>
            </ValidationObserver> -->
          </div>
        </div>
      </div>

      <template #footer>
        <PButton
          label="Quitter"
          icon="pi pi-times"
          @click="cancel"
          class="p-button-text p-button-danger"
        />
        <PButton
          label="Précédent"
          icon="pi pi-chevron-left"
          :disabled="activeStepIndex <= 0"
          class="p-button-outlined"
          @click.prevent="goToPrevious"
        />
        <PButton
          label="Suivant"
          icon="pi  pi-chevron-right"
          v-if="activeStepIndex < steps.length - 1"
          @click.prevent="goToNext"
        />
        <PButton
          class="p-button p-button-primary"
          label="Valider"
          icon="pi pi-clock"
          v-if="activeStepIndex == steps.length - 1"
          @click="save"
          autofocus
        />
      </template>
    </PDialog>
  </div>
</template>

<script>
import { paginatorMixin } from "@/mixins/paginator-mixin";
import { mapActions, mapGetters } from "vuex";
import AsyncSearchInput from "../../../../common/AsyncSearchInput.vue";
import DStepper from "../../../../common/DStepper.vue";
import SearchableItemParticipantPayementSelector from "../../../../common/SearchableItemParticipantPayementSelector.vue";
import SearchableItemEntrepriseSelector from "../../../../common/SearchableItemEntrepriseSelector.vue";
import PlacementEntrepriseSelector from "./steps/PlacementEntrepriseSelector.vue";
import PointPresenceParEntreprisePayement from "./steps/PointPresenceParEntreprisePayement.vue";
import PriseServicePayement from "./steps/PriseServicePayement.vue";
import TypeIndemniteSelector from "./steps/TypeIndemniteSelector.vue";
import BeneficiairePayementParEntreprise from "./steps/BeneficiairePayementParEntreprise.vue";

export default {
  components: {
    AsyncSearchInput,
    DStepper,
    SearchableItemParticipantPayementSelector,
    SearchableItemEntrepriseSelector,
    PointPresenceParEntreprisePayement,
    PriseServicePayement,
    BeneficiairePayementParEntreprise,
    PlacementEntrepriseSelector,
    TypeIndemniteSelector,
  },
  props: {
    title: {
      type: String,
      default: "Générer un payement d'indemnité",
    },
    size: {
      type: String,
      default: "md",
    },
    item: {
      type: Object,
      default: () => ({}),
    },
  },
  mixins: [paginatorMixin],
  data() {
    return {
      display: false,
      activeStepIndex: 0,
      activeTab: 0,
      beneficiaires: [],
      entreprises: [],
      activePlacement: null,
      activeTypeIndemnite: null,
      activePointPresence: null,
      generating: false,
      editableItem: {
        modeGeneration: null,
        entreprise: null,
        beneficiares: [],
        typeIndemniteCode: null,
      },
    };
  },
  watch: {
    item: {
      handler() {
        this.editableItem = { ...this.item };
      },
      deep: true,
    },
    "editableItem.modeGeneration": {
      handler(val) {
        if (val == "FULL") {
          this.entreprise = null;
        }
      },
      deep: true,
    },
  },
  computed: {
    referentielName() {
      return "demandeur";
    },
    stepCount() {
      return this.editableItem.modeGeneration != "FULL" ? 1 : 1;
    },
    selectedEntreprise() {
      return this.entreprises.length > 0 ? this.entreprises[0] : null;
    },
    steps() {
      let steps = [
        {
          name: "SELECTION_TYPE_INDEMNITE",
          label: "SELECTION TYPE INDEMNITE",
          subtitle: "Sélection du type d'indemnité",
          to: "#selection-type-idemnite",
          completed: this.activeStepIndex >= 0,
          command: () => {},
        },
        {
          name: "GENERATION_PAYEMENT",
          label: "GENERATION PAYEMENT",
          subtitle: "Génération de l'état de paiement",
          to: "#generation-etat-paiement",
          completed: this.activeStepIndex >= 1,
          command: () => {},
        },
      ];

      //   if (this.editableItem.typeIndemniteCode == "FRAIS_STAGE") {
      //     steps.push({
      //       name: "SELECTION_POINT_PRESENCE",
      //       label: "SELECTION DU POINT DE PRESENCE",
      //       subtitle: "Choix du point de présence",
      //       to: "#npiValidation",
      //       completed: this.activeStepIndex >= 2,
      //       command: () => {},
      //     });
      //   } else {
      //     steps.push({
      //       name: "SELECTION_BENEFICIAIRE",
      //       label: "SELECTION BENEFICIAIRE",
      //       subtitle: "Sélection des bénéficiaires",
      //       to: "#verification-dossier",
      //       completed: this.activeStepIndex >= 2,
      //       command: () => {},
      //     });
      //   }
      //   if (this.editableItem.modeGeneration != "FULL") {
      //     steps.push({
      //       name: "SELECTION_ENTREPRISE",
      //       label: "SELECTION ENTREPRISE",
      //       subtitle: "Sélection de l'entreprise",
      //       to: "#verification-dossier",
      //       completed: this.activeStepIndex >= 1,
      //       command: () => {},
      //     });
      //   }

      steps = [...steps];

      return steps;
    },
    activeStep() {
      return this.steps[this.activeStepIndex];
    },
    tableFields() {
      let fields = [
        "#",
        "index",
        { key: "beneficiaireNom", label: "Nom" },
        { key: "beneficiairePrenom", label: "Prénom" },
        { key: "beneficiairePhone", label: "Téléphone" },
        { key: "beneficiaireSexe", label: "Sexe" },
        { key: "statutPayement", label: "Statut Pay." },
        { key: "motifPayement", label: "Motif Pay." },
        { key: "actions", label: "Actions" },
      ];
      return fields;
    },
    entrepriseTableFields() {
      let fields = [
        "#",
        "index",
        { key: "ifu", label: "IFU" },
        { key: "raisonSocial", label: "Dénomination" },
        { key: "phone", label: "Téléphone" },
        { key: "commune.nom", label: "Commune" },
        { key: "departement.nom", label: "Département" },
      ];
      return fields;
    },
  },
  methods: {
    ...mapActions({
      genererPayementLiquidation: "liquidation/genererPayementLiquidation",
    }),
    handleGeneratePayement(generatorInfo) {
      this.generating = true;
      this.genererPayementLiquidation({
        modeGeneration: generatorInfo.modeGeneration,
        typeIndemniteCode: this.editableItem.typeIndemniteCode,
        liquidation: this.editableItem.liquidation,
        entreprise: this.selectedEntreprise,
        beneficiaires: generatorInfo.beneficiaires,
      })
        .then((data) => {
          this.generating = false;
          // this.queryStatistics();
          this.$toast.success("Payement généré avec succès!", {
            position: "top-right",
            duration: 8000,
          });
          //   this.$refs.ppPayement.reloadCurrentPointPresenceDetail();
        })
        .catch(() => {
          this.$toast.error("Opération non réussi!", {
            position: "top-right",
            duration: 8000,
          });
        });
    },
    resetContent() {
      this.activeStepIndex = 0;
    },
    show() {
      this.resetContent();
      this.display = true;
    },
    hide() {
      this.display = false;
    },
    cancel() {
      this.hide();
      this.$emit("canceled");
    },
    handleInput(e) {
      this.editableItem[e.target.name] = e.target.value;
      this.$emit("input", this.editableItem);
    },
    async goToNext() {
      if (!(await this.isCompleted(this.activeStepIndex))) return;
      this.activeStepIndex +=
        this.activeStepIndex < this.steps.length - 1 ? this.stepCount : 0;
    },
    async goToPrevious() {
      this.activeStepIndex -= this.activeStepIndex > 0 ? this.stepCount : 0;
    },
    async isCompleted(step) {
      //   const valid = await this.$refs["observer_" + this.activeStep.name].validate();
      //   return valid;
      return true;
    },
    extraQueryArgsEntrepriseParamBuilder() {
      return ``;
    },
    extraQueryArgsParamBuilder() {
      let queryParam = `typeIndemniteCode=${this.editableItem.typeIndemniteCode}`;
      //   if (this.entreprises.length > 0) {
      //     queryParam += `&entrepriseId=${this.entreprises[0].id}`;
      //   }
      if (this.activePlacement != null) {
        queryParam += `&placementId=${this.activePlacement.id}`;
      }

      return queryParam;
    },
    async save() {
      if (!(await this.isCompleted(this.steps.length - 1))) return;

      this.$emit("save", {
        ...this.editableItem,
        entreprise: this.entreprises[0],
      });
      this.editableItem = {};
      this.hide();
    },
  },
};
</script>

<style>
.field-checkbox,
.field-radiobutton {
  display: flex;
  justify-items: center;
}
.stepper-dialog .p-dialog-header-icons {
  display: none;
}

.stepper-dialog .p-dialog-content {
  background: #faf4fa;
}

.stepper-dialog .p-dialog-footer {
  box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.2), 0px -1px 1px rgba(0, 0, 0, 0.2);
}

.stepper-dialog .p-dialog-header {
  box-shadow: 1px 2px 0px 0px rgba(0, 0, 0, 0.5);
}

.shadow-light {
  box-shadow: 0 0.5rem 1rem rgb(0 0 0 / 3%) !important;
}

.msg-cloture.p-message .p-message-wrapper {
  padding: 0.5rem 1.2rem;
}
</style>
