<template>
  <div>
    <div class="bg-white shadow-light rounded-3 bg-r p-3">
      <h5>Quel type de paiement voulez-vous effectuer?</h5>
      <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
        <div class="card mt-4 border-0">
          <form ref="form" method="post" @submit.prevent="handleSubmit()">
            <div class="row">
              <div class="col-sm-12 field">
                <ValidationProvider rules="required" v-slot="{ errors }">
                  <ul class="list-group">
                    <li
                      class="list-group-item cursor-pointer"
                      v-for="(op, i) in typeIndemniteCodes"
                      :key="i"
                    >
                      <div class="d-flex justify-content-between">
                        <div>
                          <input
                            class="form-check-input me-1"
                            type="radio"
                            :value="op"
                            v-model="editableItem.typeIndemniteCode"
                            :name="`typeIndemniteCodeRadio`"
                            :id="`typeIndemniteCodeRadio${i}`"
                          />
                          <label
                            class="form-check-label stretched-link"
                            :for="`typeIndemniteCodeRadio${i}`"
                            >{{ op.label }}</label
                          >
                          <div class="d-flex w-100 justify-content-between">
                            <h5 class="mb-1">{{ op.label }}</h5>
                            <small>{{ op.description }}</small>
                          </div>
                          <!-- <p class="mb-1">Some placeholder content in a paragraph.</p> -->
                          <!-- <small>And some small print.</small> -->
                        </div>
                        <!-- <div>
                              <span class="text-muted">{{
                                op.entreprise.raisonSocial
                              }}</span>
                            </div> -->
                      </div>
                    </li>
                  </ul>
                  <span v-for="(err, index) in errors" :key="index" class="text-danger">{{
                    err
                  }}</span>
                </ValidationProvider>
              </div>
            </div>
          </form>
        </div>
      </ValidationObserver>
    </div>
  </div>
</template>

<script>
import { paginatorMixin } from "@/mixins/paginator-mixin";
import { mapGetters } from "vuex";

export default {
  props: ["value"],
  components: {},
  props: {
    title: {
      type: String,
      required: true,
    },
    size: {
      type: String,
      default: "md",
    },
  },
  mixins: [paginatorMixin],
  data() {
    return {
      display: false,
      error: false,
      editableItem: {
        typeIndemniteCode: this.value,
      },
    };
  },
  created() {},
  watch: {
    editableItem: {
      handler() {
        this.$emit(
          "input",
          this.editableItem.typeIndemniteCode != null
            ? this.editableItem.typeIndemniteCode.name
            : null
        );
      },
      deep: true,
    },
  },
  computed: {
    ...mapGetters({}),
    typeIndemniteCodes() {
      let typeindemnites = [
        {
          name: "FRAIS_STAGE",
          label: "ALLOCATION DE STAGE",
          description: "Allocation de stage",
        },
        {
          name: "INSTALLATION",
          label: "INSTALLATION",
          description: "Indemnité d'installation",
        },
        {
          name: "TRANSPORT",
          label: "TRANSPORT",
          description: "Indemnité de transport",
        },
      ];
      return typeindemnites;
    },
  },
  methods: {
    resetContent() {},
    show() {
      this.resetContent();
      this.display = true;
    },
    hide() {
      this.display = false;
    },
    cancel() {
      this.hide();
      this.$emit("canceled");
    },
    setPayementOption(option = {}) {
      this.editableItem = {
        ...option,
        typeIndemniteCode: null,
      };
    },
    resetContent() {},
  },
};
</script>

<style>
.field-checkbox,
.field-radiobutton {
  display: flex;
  justify-items: center;
}
.stepper-dialog .p-dialog-header-icons {
  display: none;
}

.stepper-dialog .p-dialog-content {
  background: #faf4fa;
}

.stepper-dialog .p-dialog-footer {
  box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.2), 0px -1px 1px rgba(0, 0, 0, 0.2);
}

.stepper-dialog .p-dialog-header {
  box-shadow: 1px 2px 0px 0px rgba(0, 0, 0, 0.5);
}

.shadow-light {
  box-shadow: 0 0.5rem 1rem rgb(0 0 0 / 3%) !important;
}

.msg-cloture.p-message .p-message-wrapper {
  padding: 0.5rem 1.2rem;
}
</style>
