<template>
  <div>
    <PDialog
      class="stepper-dialog"
      :header="'Selection de l\'opérateur de payement'"
      :modal="true"
      :visible.sync="display"
      :contentStyle="{ overflow: 'auto' }"
      :containerStyle="{ width: '70vw' }"
    >
      <template #header>
        <div class="card px-0 border-0 mb-2 w-100">
          <div>
            <h4 class="d-flex justify-content-between">
              <div>
                {{ title }}
              </div>
              <PButton
                @click="cancel()"
                icon="pi pi-times"
                class="p-button-rounded p-button-outlined p-button-danger"
              />
            </h4>
            <PDivider />
            Opérateur sélectionné:
            <PTag severity="danger" v-if="activeOperateur != null">{{
              activeOperateur.label
            }}</PTag>
            <PTag v-else severity="secondary">Aucun</PTag>
          </div>
        </div>
      </template>

      <div class="bg-white shadow-light rounded-3 bg-r p-3">
        <h5>Veuillez sélectionner l'opérateur à utiliser pour le paiement.</h5>
        <div class="card mt-4 border-0">
          <form ref="form" method="post">
            <div class="row">
              <div class="col-sm-12">
                <ValidationProvider rules="" v-slot="{ errors }">
                  <ul class="list-group">
                    <li class="list-group-item" v-for="(op, i) in operateurs" :key="i">
                      <div class="d-flex justify-content-between">
                        <div>
                          <!-- <input
                              class="form-check-input me-1"
                              type="radio"
                              :value="op"
                              v-model="editableItem.operateur"
                              :name="`placementRadio`"
                              :id="`placementRadio${i}`"
                            /> -->
                          <!-- <label
                              class="form-check-label stretched-link"
                              :for="`placementRadio${i}`"
                              >{{ op.label }}</label -->
                          <span>{{ op.label }}</span>
                          <div class="d-flex w-100 justify-content-between">
                            <h5 class="mb-1">{{ op.label }}</h5>
                            <small>{{ op.description }}</small>
                          </div>
                          <!-- <p class="mb-1">Some placeholder content in a paragraph.</p> -->
                          <!-- <small>And some small print.</small> -->
                        </div>
                        <div>
                          <a
                            class="btn btn-success me-2 cursor-pointer"
                            href="#"
                            @click.stop.prevent="payerViaSica(op)"
                          >
                            Payer via sica
                          </a>

                          <button
                            class="btn btn-warning cursor-pointer"
                            @click.stop.prevent="payerExterieurSica(op)"
                          >
                            Payer hors sica
                          </button>
                        </div>
                      </div>
                    </li>
                  </ul>
                  <span v-for="(err, index) in errors" :key="index" class="text-danger">{{
                    err
                  }}</span>
                </ValidationProvider>
              </div>
            </div>
          </form>
        </div>
      </div>
      <template #footer>
        <PButton
          label="Quitter"
          icon="pi pi-times"
          @click="cancel"
          class="p-button-text p-button-danger"
        />
        <!-- <PButton label="Valider" icon="pi pi-check" @click="save" autofocus /> -->
      </template>
    </PDialog>
  </div>
</template>

<script>
import { paginatorMixin } from "@/mixins/paginator-mixin";
import { mapGetters } from "vuex";

export default {
  components: {},
  props: {
    title: {
      type: String,
      required: true,
    },
    size: {
      type: String,
      default: "md",
    },
  },
  mixins: [paginatorMixin],
  data() {
    return {
      display: false,
      error: false,
      editableItem: {},
    };
  },
  created() {},
  computed: {
    ...mapGetters({
      typeAvantages: "typeAvantage/typeAvantages",
    }),
    activeOperateur() {
      return this.editableItem.operateur;
    },
    operateurs() {
      let operateurs = [
        {
          name: "FEDAPAY",
          code: "FEDAPAY",
          label: "FEDAPAY",
          description: "Aggrégateur FEDAPAY",
        },
        {
          name: "MOOV",
          code: "MOOV",
          label: "MOOV",
          description: "Choix du type de MOOV",
        },
        {
          name: "MTN",
          code: "MTN",
          label: "MTN",
          description: "Choix du type de MTN",
        },
      ];
      return operateurs;
    },
  },
  methods: {
    resetContent() {},
    show() {
      this.resetContent();
      this.display = true;
    },
    hide() {
      this.display = false;
    },
    cancel() {
      this.hide();
      this.$emit("canceled");
    },
    setPayementOption(option = {}) {
      this.editableItem = {
        ...option,
        operateur: null,
      };
    },
    payerExterieurSica(op) {
      this.editableItem.payInside = false;
      this.editableItem.operateur = op.code;
      this.save();
    },
    payerViaSica(op) {
      this.editableItem.payInside = true;
      this.editableItem.operateur = op.code;
      this.save();
    },
    resetContent() {},
    async isCompleted(step) {
      const valid = await this.$refs["observer"].validate();
      return valid;
    },
    async save() {
      //   if (!(await this.isCompleted())) return;

      this.$emit("save", {
        ...this.editableItem,
      });
      this.hide();
    },
  },
};
</script>

<style>
.field-checkbox,
.field-radiobutton {
  display: flex;
  justify-items: center;
}
.stepper-dialog .p-dialog-header-icons {
  display: none;
}

.stepper-dialog .p-dialog-content {
  background: #faf4fa;
}

.stepper-dialog .p-dialog-footer {
  box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.2), 0px -1px 1px rgba(0, 0, 0, 0.2);
}

.stepper-dialog .p-dialog-header {
  box-shadow: 1px 2px 0px 0px rgba(0, 0, 0, 0.5);
}

.shadow-light {
  box-shadow: 0 0.5rem 1rem rgb(0 0 0 / 3%) !important;
}

.msg-cloture.p-message .p-message-wrapper {
  padding: 0.5rem 1.2rem;
}
</style>
