/* eslint-disable vue/no-unused-components */
<template>
  <div class="container-fluid">
    <OperateurPayementSelectorDialog
      ref="operateurPayementSelectorDialog"
      :title="'Selection de l\'opérateur de paiement'"
      @save="payerLidationPayementViaOperateur"
    />
    <PayementIndemniteParEntrepriseGeneratorDialog
      ref="payementGeneratorDialog"
      :item="generatorItem"
      @save="generatePayementCompleted"
    />
    <div class="statistic-box">
      <div class="row gap-4">
        <div
          class="col bg- p-2 rounded border border-danger"
          v-for="(st, i) in statList"
          :key="i"
        >
          <div>{{ st.typeIndemniteCode }}</div>
          <h2>{{ st.totalMontantPercu.toFixed(3) }}</h2>
          <div class="d-flex">
            <div>
              <span>Montant:</span>
              <h6>{{ st.totalMontant.toFixed(3) }}</h6>
            </div>
            <PDivider layout="vertical" />
            <div>
              <span>Frais Envoi:</span>
              <h6>{{ st.totalCoutTransfert.toFixed(3) }}</h6>
            </div>
          </div>
        </div>
      </div>
    </div>
    <PDivider />
    <div class="container- mt-1">
      <p-progress-bar v-if="mxLoading" style="height: 0.33em" mode="indeterminate" />
      <div class="bg-white p-2 rounded-4">
        <form class="bg-" method="post" accept-charset="utf-8" action="">
          <div class="row py-0 p-fluid grid formgrid">
            <div class="field col-12 col-md-3">
              <!-- <label for="basic">Date de début</label> -->
              <p-calendar
                placeholder="Date de début"
                v-model="dateDeb"
                locale="fr"
                :showIcon="true"
                :manualInput="false"
              />
            </div>
            <div class="field col-12 col-md-3">
              <!-- <label for="basic">Date de fin</label> -->
              <p-calendar
                placeholder="Date de fin"
                class="flex-grow-1"
                v-model="dateFin"
                :showIcon="true"
                :manualInput="false"
              />
            </div>
            <div class="col field">
              <form class="d-flex my-1">
                <div class="input-group me-4">
                  <div class="p-inputgroup">
                    <PInputText
                      type="text"
                      v-model="mxSearch"
                      :placeholder="'Rechercher par'"
                    />
                    <p-multi-select
                      v-model="mxSearchFields"
                      :options="searchFieldsOptions.filter((el) => el.visible)"
                      optionLabel="label"
                      optionValue="value"
                      :editable="true"
                    />
                  </div>
                </div>
              </form>
            </div>
            <div class="col-auto">
              <div class="d-grid gap-2">
                <a
                  href="#"
                  @click.prevent="mxSubmitQuery"
                  class="style_btn btn btn-primary"
                  id="button-query"
                  ><i class="bi bi-search"></i
                ></a>
              </div>
            </div>
            <div class="col-auto">
              <button
                type="button"
                @click.prevent="showAdvancedFilter = !showAdvancedFilter"
                class="style_btn btn btn-warning"
                id="button-query"
              >
                <i class="bi bi-filter"></i>
              </button>
            </div>
          </div>
        </form>
        <transition>
          <div class="card mx- border-radius-5 mt-2 hadow-5" v-if="showAdvancedFilter">
            <div class="row p-fluid p-3 grid">
              <template v-for="(option, index) in filterOptions">
                <div class="col-12 col-md-3 mb-4" v-if="option.visible" :key="index">
                  <label for="basic">{{ option.label }}</label>
                  <c-auto-complete
                    v-if="option.url"
                    display="chip"
                    v-model="option.value"
                    chips
                    :option="option"
                    :force-selection="true"
                  />
                  <p-multi-select
                    v-else-if="option.items && option.items.length != 0"
                    v-model="option.value"
                    :options="option.items"
                    :optionLabel="option.key"
                    :placeholder="option.label"
                    display="chip"
                  />
                  <p-input-text
                    v-else
                    v-model="option.value"
                    :placeholder="option.label"
                  />
                </div>
              </template>
            </div>
          </div>
        </transition>
      </div>
      <p-divider />
      <div class="d-flex flex-wrap mb-4 justify-content-between align-items-center">
        <div class="my-1 flex-fill">
          <h4>Liste des payements</h4>
        </div>
        <div class="btn-group">
          <a
            @click.prevent="genererPayementLiquidationList(entreprise)"
            href=""
            class="ms-2 style_btn btn btn-warning"
          >
            Ajouter un payement
          </a>
        </div>
        <PMessage
          v-show="errorMessage"
          :severity="'error'"
          @close="errorMessage = null"
          >{{ errorMessage }}</PMessage
        >
        <div v-if="$can('export', 'Azoli')" class="btn-group ms-4">
          <PSplitButton
            label="Exporter"
            :model="exportActions"
            class="p-button-outlined p-button-primary"
          />
        </div>
        <div class="btn-group ms-4">
          <button
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            title="Affichage mode tableau"
            @click.prevent="viewMode = 'card'"
            class="btn style_btn btn-md"
            :class="{
              'btn-success': viewMode == 'card',
              'btn-light': viewMode == 'table',
            }"
          >
            <i class="bi-grid" />
          </button>
          <button
            @click.prevent="viewMode = 'table'"
            class="btn style_btn"
            :class="{
              'btn-light': viewMode == 'card',
              'btn-success': viewMode == 'table',
            }"
          >
            <i class="bi-table" />
          </button>
        </div>
      </div>

      <template v-if="!mxLoading">
        <div class="bg-">
          <div class="bg-white p-3 border">
            <div class="table-responsive">
              <b-table
                show-empty
                empty-text="Aucune donnée à afficher"
                :fields="tableFields"
                class="align-middle"
                striped
                select-mode="multi"
                ref="table"
                selectable
                @row-selected="onRowSelected"
                :items="tableData"
              >
                <template #head()="data">
                  <span>{{ data.label }} </span>
                </template>
                <template #head(#)="data">
                  <div class="form-check form-check-inline">
                    <input
                      @change="selectAll"
                      class="form-check-input"
                      type="checkbox"
                      :id="`${data.index}`"
                      name="disponible"
                      :value="false"
                    />
                  </div>
                </template>
                <!-- A virtual column -->
                <template #cell(index)="data">
                  {{ data.index + 1 }}
                </template>

                <template #cell(#)="data">
                  <div class="form-check form-check-inline">
                    <input
                      @input="selectionChanged($event, data)"
                      :checked="data.rowSelected"
                      class="form-check-input"
                      type="checkbox"
                      :id="`${data.index}`"
                      name="disponible"
                    />
                  </div>
                </template>

                <template #cell(status)="{ item: cand }">
                  <PTag
                    class="text-uppercase badge badge-primary"
                    :severity="getStatusColor(cand.status)"
                    v-if="cand.status"
                    >{{ cand.status }}
                  </PTag>
                </template>
                <template #cell(isEvalue)="{ item: de }">
                  <span class="text-uppercase">
                    <i
                      v-if="de.isEvalue"
                      v-tooltip.left="'Evaluée'"
                      class="bi bi-check-circle text-success"
                      style="font-size: 1.5rem"
                      v-badge.success="de.typeCandidature == 'RECOMMANDE' ? 'R' : 'S'"
                    ></i>
                    <i
                      v-else
                      v-tooltip.left="'Non évaluée'"
                      class="bi bi-slash-circle text-danger"
                      style="font-size: 1.5rem"
                      v-badge.danger="de.typeCandidature == 'RECOMMANDE' ? 'R' : 'S'"
                    ></i>
                  </span>
                </template>
                <template #cell(nom)="{ item: de }">
                  <span class="text-uppercase">{{ de.nom }}</span>
                </template>
                <template #cell(dateDebut)="{ item: de }">
                  <span class="text-uppercase">{{
                    $moment(de.dateDebut).format("DD-MM-YYYY")
                  }}</span>
                </template>
                <template #cell(dateFin)="{ item: de }">
                  <span class="text-uppercase">{{
                    $moment(de.dateFin).format("DD-MM-YYYY")
                  }}</span>
                </template>

                <template #cell(dateMoisLiquidation)="{ item: de }">
                  <span class="text-uppercase">{{
                    de.dateMoisLiquidation != null
                      ? $moment(de.dateMoisLiquidation).format("MMM YYYY")
                      : "-"
                  }}</span>
                </template>

                <template #row-details="row">
                  <PTabView :activeIndex.sync="activeTab" class="mt-5">
                    <PTabPanel header="Etat de paiement">
                      <PayementIndemniteList
                        :liquidation="row.item"
                        :extraQueryArg="`liquidationId=${row.item.id}`"
                        v-if="activeTab == 0"
                      />
                    </PTabPanel>
                  </PTabView>
                </template>

                <template #cell(actions)="row">
                  <div class="d-flex justify-content-end">
                    <button
                      class="btn btn-sm py-1 btn-outline-primary me-1"
                      v-tooltip.top="'Voir les éétails'"
                      @click="mxToggleDetails(row, tableData)"
                    >
                      <i class="bi bi-journal-check"></i>
                    </button>
                  </div>
                </template>
                <!-- Optional default data cell scoped slot -->
                <template #cell()="data">
                  <span>{{ data.value }}</span>
                </template>
              </b-table>
            </div>
            <div class="d-flex flex-wrap justify-content-between align-items-center">
              <p>
                Affichage de l’élément
                {{ mxPagination.page * mxPagination.rowsPerPage }} à
                {{ (mxPagination.page + 1) * mxPagination.rowsPerPage }} de
                {{ mxTotalCount }} éléments
              </p>
              <b-pagination
                v-model="mxPagination.page"
                :total-rows="mxTotalCount"
                :per-page="mxPagination.rowsPerPage"
                aria-controls="my-table"
              ></b-pagination>
            </div>
          </div>
        </div>
      </template>
      <template v-else>
        <div>
          <PDataTable
            :value="
              [1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((e) => {
                e;
              })
            "
          >
            <PColumn
              v-for="f in tableFields"
              :key="f.key"
              :field="f.key"
              :header="f.label"
            >
              <template #body>
                <PSkeleton></PSkeleton>
              </template>
            </PColumn>
          </PDataTable>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { paginatorMixin } from "@/mixins/paginator-mixin";
import { authMixin } from "../../../../mixins/auth-mixin";

import PayementIndemniteList from "./PayementIndemniteList.vue";
import OperateurPayementSelectorDialog from "../../../../components/espace/common/payement/editor/OperateurPayementSelectorDialog.vue";
import PayementIndemniteParEntrepriseGeneratorDialog from "../../../../components/espace/common/payement/editor/PayementIndemniteParEntrepriseGeneratorDialog.vue";

export default {
  props: {
    extraQueryArg: {
      type: String,
      default: "",
    },
    entreprise: {
      type: Object,
      default: null,
    },
  },
  components: {
    PayementIndemniteList,
    OperateurPayementSelectorDialog,
    PayementIndemniteParEntrepriseGeneratorDialog,
  },
  mixins: [paginatorMixin, authMixin],
  data() {
    return {
      selected: [],
      search: "",
      activePriseDeService: {},
      activeCessationService: {},
      activeLiquidation: {},
      liquidation: null,
      dateDeb: null,
      dateFin: null,
      activeTab: 0,
      statList: [
        [
          {
            typeIndemniteCode: "FRAIS_STAGE",
            totalMontant: 0,
            totalCoutTransfert: 0,
            totalMontantPercu: 0,
          },
          {
            typeIndemniteCode: "INSTALLATION",
            totalMontant: 0,
            totalCoutTransfert: 0,
            totalMontantPercu: 0,
          },
          {
            typeIndemniteCode: "TRANSPORT",
            totalMontant: 0,
            totalCoutTransfert: 0,
            totalMontantPercu: 0,
          },
          {
            typeIndemniteCode: "TOTAL",
            totalMontant: 0,
            totalCoutTransfert: 0,
            totalMontantPercu: 0,
          },
        ],
      ],
      generatorItem: {},
      typeIndemniteCode: "FRAIS_STAGE",
      errorMessage: null,
      viewMode: localStorage.getItem("viewMode") || "card", //card, table
      showAdvancedFilter: false,
      nonEvalueesCountLoading: false,
      femmesAvecEnfantsCount: 0,
      nombreEnfants3Mois3AnsCount: 0,
      placementValidation: {},
      currentOffre: {},
      activeTransliquidation: {},
      activeRecommandation: {
        demandeur: [],
      },
      exportActions: [
        // {
        // 	label: 'PDF',
        // 	icon: 'pi pi-file-pdf',
        // 	command: () => {
        //     const data = XLSX.utils.json_to_sheet(this.tableData.map(el => ({ ...el, ...el.azoli })))
        //     const wb = XLSX.utils.book_new()
        //     XLSX.utils.book_append_sheet(wb, data, 'data')
        //     XLSX.writeFile(wb,'Liste des bénéficiaires Azoli.xlsx')
        // 	}
        // },
        {
          label: "Excel",
          icon: "pi pi-file-excel",
          command: () => {
            let params = this.mxGetQueryBuilder();
            let route = this.$router.resolve({
              name: "common.export",
              query: {
                dataGetter: this.mxDataGetter,
                fetcherMethod: this.mxFetcherMethod,
                queryParams: params,
                fileName: "Liste des bénéficiaires Azoli.xlsx",
              },
            });
            window.open(route.href, "_blank");
            // this.mxExportToExcel().then(items => {
            //   const data = XLSX.utils.json_to_sheet(items.map(el => ({
            //     ...el,
            //     ...el.azoli,
            //     commune: el.commune.name,
            //     departement: el.departement.name,
            //     village: el.ville.name,
            //     arrondissement: el.arrondissement.name,
            //   })))
            //   const wb = XLSX.utils.book_new()
            //   XLSX.utils.book_append_sheet(wb, data, 'data')
            //   XLSX.writeFile(wb,'Liste des bénéficiaires Azoli.xlsx')
            // })
          },
        },
      ],
      searchFields: ["nom"],
      searchFieldsOptions: [
        {
          label: "Bénéficiaire",
          value: "nom",
          visible: this.$can("filter.beneficiaire", "Azoli"),
        },
        {
          label: "Téléphone",
          value: "phone",
          visible: this.$can("filter.telephone", "Azoli"),
        },
        {
          label: "Whatsapp",
          value: "whatsapp",
          visible: this.$can("filter.whatsapp", "Azoli"),
        },
        {
          label: "Commune",
          value: "commune",
          visible: this.$can("filter.commune", "Azoli"),
        },
        {
          label: "Département",
          value: "departement",
          visible: this.$can("filter.departement", "Azoli"),
        },
        {
          label: "AE (Nom & prénom)",
          value: "ae",
          visible: this.$can("filter.ae", "Azoli"),
        },
        {
          label: "Arrondissement",
          value: "arrondissement",
          visible: this.$can("filter.arrondissement", "Azoli"),
        },
        {
          label: "Village/Quartier de ville",
          value: "ville",
          visible: this.$can("filter.ville", "Azoli"),
        },
        {
          label: "Métier Appris",
          value: "metierAppris",
          visible: this.$can("filter.metierAppris", "Azoli"),
        },
        {
          label: "Métier Désiré",
          value: "metierDesire",
          visible: this.$can("filter.metierDesire", "Azoli"),
        },
      ],
      filterOptions: [
        {
          column: "nom",
          value: "",
          label: "Bénéficiaire",
          dropdown: false,
          visible: this.$can("filter.beneficiaire", "Azoli"),
        },
        {
          column: "phone",
          value: "",
          label: "Téléphone",
          dropdown: false,
          visible: this.$can("filter.telephone", "Azoli"),
        },
        {
          column: "creePar",
          value: "",
          label: "AE (Nom & prénom)",
          dropdown: false,
          visible: this.$can("filter.ae", "Azoli"),
        },
        {
          column: "departement",
          key: "nom",
          valueKey: "nom",
          value: "",
          label: "Département",
          dropdown: true,
          url: "/departements",
          visible: this.$can("filter.departement", "Azoli"),
        },
        {
          column: "commune",
          key: "nom",
          valueKey: "nom",
          value: "",
          label: "Commune",
          dropdown: true,
          url: "/communes",
          visible: this.$can("filter.commune", "Azoli"),
        },
        {
          column: "arrondissement",
          key: "nom",
          valueKey: "nom",
          value: "",
          dropdown: true,
          url: "/arrondissements",
          label: "Arrondissement",
          visible: this.$can("filter.arrondissement", "Azoli"),
        },
        {
          column: "ville",
          key: "nom",
          valueKey: "nom",
          value: "",
          dropdown: true,
          url: "/villes",
          label: "Village/Quartier de ville",
          visible: this.$can("filter.ville", "Azoli"),
        },
        {
          column: "status",
          key: "label",
          value: "",
          label: "Statut du dossier",
          multiple: true,
          valueKey: "value",
          items: [
            { value: "NOUVEAU", label: "Sans dossier" },
            { value: "EN_COURS", label: "En cours" },
            { value: "TERMINE", label: "Terminé" },
            { value: "VALIDE", label: "Validé" },
          ],
          visible: this.$can("filter.status", "Azoli"),
        },
        {
          column: "metierAppris",
          key: "libelle",
          valueKey: "libelle",
          value: "",
          label: "Metier appris",
          dropdown: true,
          url: "/romes",
          visible: this.$can("filter.metierAppris", "Azoli"),
        },
        {
          column: "metierDesire",
          key: "libelle",
          valueKey: "libelle",
          value: "",
          label: "Metier recherché",
          dropdown: true,
          url: "/romes",
          visible: this.$can("filter.metierDesire", "Azoli"),
        },
      ],
      filters: {
        skills: [],
        availabilities: [],
        price_min: null,
        price_max: null,
        departement: null,
        _queryOptions: [
          {
            column: "departement",
            value: null,
            label: "Département",
          },
          {
            column: "commune",
            value: null,
            label: "Commune",
          },
        ],
        ordering: "",
        // _orderingOptions: {
        // }
      },
      activeCandidature: {},
      activeCandidature: {},
      breadcrumbs: [
        {
          text: "Admin",
          href: "#",
        },
        {
          text: "Paramètres",
          href: "#",
        },
        {
          text: "Demandeurs",
          active: true,
        },
      ],
    };
  },
  created() {
    this.loadData();
  },
  watch: {
    dossier(val) {
      if (val.azoli) {
        this.activeCandidature = {
          ...val,
          ...val.azoli,
        };
      }
    },
    viewMode() {
      localStorage.setItem("viewMode", this.viewMode);
    },
  },
  computed: {
    ...mapGetters({
      dossier: "demandeur/demandeur",
      statutDossiers: "setting/statutDossiers",
      // demandeurs: 'demandeur/demandeurs',
      pagination: "demandeur/pagination",
      fetchPayementPerLiquidationCountStatistics:
        "statistic/fetchPayementPerLiquidationCountStatistics",
    }),
    emptyPlacementVal() {
      return {
        posteOccupe: null,
        observation: null,
        typePlacement: null,
        natureContrat: null,
        dateDebut: null,
        dateFin: null,
      };
    },
    emptyPlacementVal() {
      return {
        posteOccupe: null,
        observation: null,
        typePlacement: null,
        natureContrat: null,
        dateDebut: null,
        dateFin: null,
      };
    },
    tableFields() {
      return [
        "#",
        { key: "reference", label: "Réf" },
        { key: "libelle", label: "Libellé" },
        { key: "dateMoisLiquidation", label: "Mois" },
        { key: "status", label: "Statut" },
        "actions",
      ];
    },
    tableData() {
      return this.mxFilteredItems.map((item) => {
        return { ...item };
      });
    },
    // currentOffre() {
    //   const offre = this.mxFilteredItems[0] != null? this.mxFilteredItems[0].offre : {}
    //   return offre
    // }
  },
  methods: {
    ...mapActions({
      createOrUpdatePriseService: "priseService/createOrUpdatePriseService",
      createOrUpdateArretContrat: "arretContrat/createOrUpdateArretContrat",
      createOrUpdateLiquidation: "liquidation/createOrUpdateLiquidation",
      cloturerLiquidation: "liquidation/cloturerLiquidation",
      validerLiquidation: "liquidation/validerLiquidation",
      recommanderProfils: "offre/recommanderProfils",
      telechargerPayementLiquidationViaOperatorToExcel:
        "liquidation/telechargerPayementLiquidationViaOperatorToExcel",
      fetchPayementPerIndemniteCountStatistics:
        "statistic/fetchPayementPerIndemniteCountStatistics",
    }),
    generatePayementCompleted() {
      this.loadData();
    },
    saveLiquidation(liquidation) {
      this.createOrUpdateLiquidation(liquidation)
        .then((data) => {
          this.$toast.success("Présence au poste sauvegardée avec succès!", {
            position: "top-right",
            duration: 8000,
          });
        })
        .catch((err) => err);
    },
    genererPayementLiquidationList(entreprise) {
      this.generatorItem = {
        typeIndemniteCode: this.typeIndemniteCode,
        liquidation: this.liquidation,
        entreprise,
      };
      this.$refs.payementGeneratorDialog.show();
    },
    exportExcelForOperator(item) {
      this.telechargerPayementLiquidationViaOperatorToExcel({
        liquidationId: this.liquidation.id,
        extraQueryArg: `${this.mxGetQueryBuilder()}&operator=${item.operator}`,
      });
      //http://localhost:8000/api/payementLiquidations/12/exportLiquidationToPdf?size=12&sort=id,desc&dateDeb=2021-12-31&dateFin=2080-12-31&liquidationId=12&page=0
    },
    async payerLidationPayementViaOperateur(item) {
      if (item.payInside) {
        if (item.ciblePayement == "TOUT") {
          await this.$confirm.require({
            group: "confirmDialog",
            message: `Voulez-vous vraiment payer tous les payements en attente de la liquidation actuellle via l'opérateur ${item.operateur.label}?`,
            header: "Confirmation",
            icon: "pi pi-exclamation-triangle",
            accept: () => {
              this.paymentOngoing = true;
              this.payerPayementLiquidations({
                liquidation: this.liquidation,
                operateur: item.operateur.name,
              }).then((data) => {
                this.paymentOngoing = false;
                this.loadData();
              });
            },
          });
        } else {
          await this.$confirm.require({
            group: "confirmDialog",
            message: `Voulez-vous vraiment payer tous les payements en attente de ${item.beneficiaire.nom} ${item.beneficiaire.prenom} sur le numéro de téléphone ${item.beneficiaire.phone}  via l'opérateur ${item.operateur.label}??`,
            header: "Confirmation",
            icon: "pi pi-exclamation-triangle",
            accept: () => {
              this.paymentOngoing = true;
              this.payerPayementLiquidationPourTelehone({
                liquidation: this.liquidation,
                phone: item.beneficiaire.phone,
                operateur: item.operateur.name,
              })
                .then((data) => {
                  this.loadData();
                  this.paymentOngoing = false;
                })
                .catch((err) => {
                  this.paymentOngoing = false;
                });
            },
          });
        }
      } else {
        this.exportExcelForOperator(item);
      }
    },
    getStatusColor(status) {
      let color = "";
      if (status == "EN_ATTENTE") {
        color = "primary";
      } else if (status == "PAYE") {
        color = "success";
      } else if (status == "ANNULE") {
        color = "danger";
      } else if (status == "EN_COURS") {
        color = "warning";
      }
      return color;
    },
    loadData() {
      this.queryStatistics();
      this.fetchPayementPerIndemniteCountStatistics({
        extraQueryArg: `liquidation.ownerObjectId=${this.entreprise.id}`,
      }).then((data) => {
        this.statList = data;
      });
    },
    queryStatistics() {
      this.mxInitializePaginator({
        queryModel: "liquidation",
        search: this.search,
        fetcherMethod: "liquidation/fetchLiquidations",
        dataGetter: "liquidation/liquidations",
        paginationGetter: "liquidation/pagination",
        autoSubmitQuery: false,
        pagination: {
          sortBy: "id",
          descending: false,
          page: 0,
          rowsPerPage: 20,
          sortingParam: `sort=id,desc`,
        },
        filterOptions: this.filterOptions,
        extraQueryArgsParamBuilder: () => {
          const dateDeb = (this.dateDeb || new Date("2021-12-31"))
            .toISOString()
            .substring(0, 10);
          const dateFin = (this.dateFin || new Date("2080-12-31"))
            .toISOString()
            .substring(0, 10);
          return `createdAfter=${dateDeb}&createdBefore=${dateFin}&${this.extraQueryArg}`;
        },
        searchFields: this.searchFields,
      });
    },
    selectionChanged(evt, data) {
      console.log(evt.target.checked, data);

      if (evt.target.checked) {
        this.$refs.table.selectRow(data.index);
      } else {
        this.$refs.table.unselectRow(data.index);
      }
    },
    onRowSelected(items) {
      this.selected = items;
    },
    selectAll(evt) {
      if (evt.target.checked) {
        this.$refs.table.selectAllRows();
      } else {
        this.$refs.table.clearSelected();
      }
    },
    getCurrentOffre() {
      const offre = this.mxFilteredItems[0] != null ? this.mxFilteredItems[0].offre : {};
      return offre;
    },
    async showValiderLiquidation(liquidation) {
      await this.$confirm.require({
        group: "confirmDialog",
        message: `Voulez-vous vraiment clôturer le point de présence du mois de ${this.$moment(
          liquidation.dateMoisLiquidation
        ).format("MMM YYYY")} ?`,
        header: "Confirmation",
        icon: "pi pi-exclamation-triangle",
        accept: () => {
          this.validerLiquidation({
            ...liquidation,
          }).then((data) => {
            this.$toast.success("Recommandation retirée avec succès!", {
              position: "top-right",
              duration: 8000,
            });
            // this.loadData()
          });
        },
      });
    },
    async showTransliquidationDialog() {
      this.activeTransliquidation = {
        offreId: this.offreId,
        candidatures: [],
      };
      this.nonEvalueesCountLoading = true;
      let nonEvalueesCount = await this.fetchCandidatureNonEvaluesCount({
        offreId: this.offreId,
      });
      this.nonEvalueesCountLoading = false;

      let offre = this.currentOffre;

      if (offre.niveauService != "SERVICE_NON_DELEGUE" && nonEvalueesCount > 0) {
        let message = `
          Cette offre a un niveau de servce ${offre.niveauService} et comporte ${nonEvalueesCount} candidature(s) non évaluée(s).\n
          Toutes les candidatures doivent être évaluées avant de transmettre les propositions à l'employeur.
        `;
        await this.$confirm.require({
          group: "confirmDialog",
          message,
          header: "Transfert non autorisé",
          icon: "pi pi-exclamation-triangle",
          rejectClass: "d-none",
          accept: () => {},
        });

        // Tant que toutes les candidatures n'ont pas été évaluée. Ne pas continuer.
        return;
      }

      this.$refs.transliquidationDialog.show();
    },

    async transmettrePropositions(candidatures) {
      // const offre = this.mxFilteredItems[0] != null? this.mxFilteredItems[0].offre : {}
      await this.$confirm.require({
        group: "confirmDialog",
        message: `Voulez-vous vraiment transmettre les candidatures sélectionnées ?`,
        header: "Confirmation",
        icon: "pi pi-exclamation-triangle",
        accept: () => {
          this.transmettrePropositionCandidatures({
            offreId: this.offreId,
            candidatures,
          }).then((data) => {
            this.loadData();
          });
        },
      });
    },
  },
};
</script>

<style>
.ellipsis {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
</style>
