
<template>
  <div class="pt-2 pb-5">
    Sélectionnées <PTag>{{ saved.length }}</PTag>
    <button @click.prevent="clearSelection" class="btn btn-sm btn-danger">
      <i class="bi bi-close"></i>Vider
    </button>
    <div class="bg">
      <div class="d-flex flex-wrap mb-4 justify-content-between align-items-center">
        <div class="my-1 flex-fill">
          <slot name="listTitle">
            <h6>{{ listTitle }}</h6>
          </slot>
        </div>
      </div>
      <div>
        <slot name="listDescription"></slot>
      </div>
      <div class="bg-white p-3 border shadow-0">
        <div class="container- mt-1">
          <p-progress-bar v-if="mxLoading" style="height: 0.33em" mode="indeterminate" />
          <div class="bg-white p-2 rounded-4">
            <form class="bg-" method="post" accept-charset="utf-8" action="">
              <div class="row py-0 p-fluid grid formgrid">
                <div class="col field col-md-8">
                  <form class="d-flex justi">
                    <div class="input-group">
                      <PInputText
                        type="text"
                        v-model="mxSearch"
                        placeholder="Rechercher par nom et prénom ou par motif"
                      />
                    </div>
                    <a
                      href="#"
                      @click.prevent="mxSubmitQuery"
                      class="style_btn btn btn-primary"
                      id="button-query"
                      ><i class="bi bi-search"></i
                    ></a>
                  </form>
                </div>
                <div class="col-md-4">
                  <div class="d-flex justify-">
                    <p-dropdown
                      class="flex-grow-1 me-2"
                      v-model="statutPayement"
                      :options="statutPayementOptions"
                      optionLabel="label"
                      optionValue="value"
                      :placeholder="'Statut payement'"
                      display="chip"
                    />
                    <button
                      type="button"
                      @click.prevent="showAdvancedFilter = !showAdvancedFilter"
                      class="style_btn btn btn-warning"
                      id="button-query"
                    >
                      <i class="bi bi-filter"></i>
                    </button>
                  </div>
                </div>
              </div>
              <div class="d-flex justify-content-between align-items-center">
                <div class="row py-0 mt-4 grid formgrid">
                  <!-- <div class="field col-12 col-md-6">
                      <p-calendar
                        placeholder="Date de début"
                        v-model="dateDeb"
                        locale="fr"
                        :showIcon="true"
                        :manualInput="false"
                      />
                                  
                    </div>
                    <div class="field col-12 col-md-6">
                      <p-calendar
                        placeholder="Date de fin"
                        v-model="dateFin"
                        :showIcon="true"
                        :manualInput="false"
                        />
                    </div> -->
                </div>
                <!-- <div class="">
                    <button type="button"  @click.prevent="showAdvancedFilter = !showAdvancedFilter" class="style_btn btn btn-warning"  id="button-query"><i class="bi bi-filter" ></i></button>
                  </div> -->
              </div>
            </form>
            <transition>
              <div
                class="card mx- border-radius-5 mt-2 hadow-5"
                v-if="showAdvancedFilter"
              >
                <div class="row p-fluid p-3 grid">
                  <template class="mb-2">
                    <div class="field col-12 col-md-6">
                      <p-calendar
                        placeholder="Date de début"
                        v-model="dateDeb"
                        locale="fr"
                        :showIcon="true"
                        :manualInput="false"
                      />
                    </div>
                    <div class="field col-12 col-md-6">
                      <p-calendar
                        placeholder="Date de fin"
                        v-model="dateFin"
                        :showIcon="true"
                        :manualInput="false"
                      />
                    </div>
                  </template>
                  <template v-for="(option, index) in filterOptions">
                    <div class="col-12 col-md-4 mb-4" v-if="option.visible" :key="index">
                      <label for="basic">{{ option.label }}</label>
                      <c-auto-complete
                        v-if="option.url || option.urlBuilder"
                        display="chip"
                        v-model="option.value"
                        chips
                        :option="option"
                        :force-selection="true"
                      />
                      <p-multi-select
                        v-else-if="option.items && option.items.length != 0"
                        v-model="option.value"
                        :options="option.items"
                        :optionLabel="option.key"
                        :placeholder="option.label"
                        display="chip"
                      />
                      <p-input-text
                        v-else
                        v-model="option.value"
                        :placeholder="option.label"
                      />
                    </div>
                  </template>
                </div>
              </div>
            </transition>
          </div>
        </div>
        <div class="table-responsive">
          <b-table
            show-empty
            empty-text="Aucune donnée à afficher"
            :fields="tableFields"
            class="align-middle"
            striped
            select-mode="multi"
            ref="table"
            :sort-by.sync="mxPagination.sortBy"
            :sort-desc.sync="mxPagination.sortDesc"
            responsive="sm"
            selectable
            sort-icon-left
            @row-selected="onRowSelected"
            :items="tableData"
          >
            <template #head()="data">
              <span>{{ data.label }} </span>
            </template>
            <template #head(index)="">
              <span> N° </span>
            </template>

            <template #head(#)="data">
              <div class="form-check">
                <input
                  @change="selectAll"
                  class="form-check-input"
                  type="checkbox"
                  :id="`${data.index}`"
                  name="disponible"
                  :value="false"
                />
              </div>
            </template>
            <template #cell(#)="data">
              <div class="form-check">
                <input
                  @input="selectionChanged($event, data)"
                  :checked="data.rowSelected"
                  class="form-check-input"
                  type="checkbox"
                  :disabled="data.item.statutPayement != null"
                  :id="`${data.index}`"
                  name="disponible"
                />
              </div>
            </template>

            <!-- A virtual column -->
            <template #cell(index)="data">
              {{ data.index + 1 }}
            </template>
            <template #cell(enabled)="data">
              <div class="form-check form-switch">
                <input
                  class="form-check-input"
                  v-model="data.item.enabled"
                  type="checkbox"
                  id="flexSwitchCheckChecked"
                  :checked="data.item.enabled"
                />
                <label class="form-check-label" for="flexSwitchCheckChecked">{{
                  data.item.enabled ? "Actif" : "Inactif"
                }}</label>
              </div>
            </template>
            <template #cell(estSuivi)="{ item }">
              <div class="position-relative">
                <PTag
                  rounded
                  :severity="item.estSuivi ? 'primary' : 'danger'"
                  size="large"
                  >{{ item.estSuivi ? "Affecté" : "Non.Affecté" }}</PTag
                >
              </div>
            </template>

            <template #cell(actions)="data">
              <div class="d-flex justify-content-end">
                <slot name="actions" :data="data">
                  <button class="btn btn-sm py-1 btn-outline-primary">
                    <i class="bi bi-journal-check"></i>
                  </button>
                </slot>
              </div>
            </template>
            <!-- Optional default data cell scoped slot -->
            <template #cell()="data">
              <span>{{ data.value }}</span>
            </template>
            <template #cell(statutPayement)="data">
              <span class="d-flex justify-content-"
                <PTag :severity="getStatutPayementColor(data.item)">{{
                  getStatutPayementLabel(data.item)
                }}</PTag></span
              >
            </template>
            <template #row-details="row">
              <slot name="rowDetails" :row="row"> rowDetails slot not provided </slot>
            </template>
          </b-table>
        </div>
        <div class="d-flex flex-wrap justify-content-between align-items-center">
          <p>
            Affichage de l’élément {{ mxPagination.page * mxPagination.rowsPerPage }} à
            {{ (mxPagination.page + 1) * mxPagination.rowsPerPage }} de
            {{ mxTotalCount }} éléments
          </p>
          <b-pagination
            v-model="mxPagination.page"
            :total-rows="mxTotalCount"
            :per-page="mxPagination.rowsPerPage"
            aria-controls="my-table"
          ></b-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { paginatorMixin } from "@/mixins/paginator-mixin";
import { multiPageItemSelectorMixin } from "@/mixins/multipage-item-selector-mixin";
import CAutoComplete from "./CAutoComplete.vue";

export default {
  props: {
    listTitle: {
      type: String,
      default: "Référentiel non précisé",
    },
    referentielName: {
      type: String,
      required: true,
    },
    tableFields: {
      type: Array,
      required: true,
    },
    value: {
      type: Array,
      default: () => [],
    },
    emptyReferentielItem: {
      type: Object,
      required: true,
    },
    dataFormatter: {
      type: Function,
    },
    typeIndemniteCode: {
      type: String,
      required: true
    },
    extraQueryArgsParamBuilder: {
      type: Function,
      default: null,
    },
    paginatorConfig: {
      type: Object,
      default: () => {},
      required: false,
    },
  },
  components: {
    CAutoComplete,
  },
  mixins: [paginatorMixin, multiPageItemSelectorMixin],
  data() {
    return {
      selected: [],
      selectedItems: [],
      saved: [],
      search: "",
      dateDeb: null,
      dateFin: null,
      filterOptions: [],
      showAdvancedFilter: false,
      activeReferentiel: { ...this.emptyReferentielItem },
      searchFields: ["beneficiaire", "motifPayement"],
      searchFieldsOptions: [
        {
          label: "Bénéficiaire",
          value: "nom",
          visible: this.$can("filter.beneficiaire", "Azoli"),
        },
      ],
      statutPayementOptions: [
        { value: null, label: "Tous" },
        { value: "VIDE", label: "NON GENERE" },
        { value: "EN_ATTENTE", label: "EN_ATTENTE" },
        { value: "PAYE", label: "PAYE" },
      ],
      statutPayement: null,
      filterOptions: [
        {
          column: "departement",
          key: "nom",
          valueKey: "nom",
          value: "",
          label: "Département",
          dropdown: true,
          url: "/departements",
          visible: true,
        },
        {
          column: "commune",
          key: "nom",
          valueKey: "nom",
          value: "",
          label: "Commune",
          dropdown: true,
          enabled: () => {
            return this.filterOptions[0].value && this.filterOptions[0].value.id;
          },
          urlBuilder: () => {
            return `/departements/${this.filterOptions[0].value.id}/communes`;
          },
          visible: true,
        },
        {
          column: "arrondissement",
          key: "nom",
          valueKey: "nom",
          value: "",
          dropdown: true,
          label: "Arrondissement",
          enabled: () => {
            return this.filterOptions[1].value && this.filterOptions[1].value.id;
          },
          urlBuilder: () => {
            return `/communes/${this.filterOptions[1].value.id}/arrondissements`;
          },
          visible: true,
        },
        {
          column: "ville",
          key: "nom",
          valueKey: "nom",
          value: "",
          dropdown: true,
          url: "/villes",
          label: "Village/Quartier",
          enabled: () => {
            return this.filterOptions[2].value && this.filterOptions[2].value.id;
          },
          urlBuilder: () => {
            return `/arrondissements/${this.filterOptions[2].value.id}/villes`;
          },
          visible: true,
        },
      ],
    };
  },
  watch: {
    statutPayement() {
      this.makeQuery();
    },
    selected: {
      handler() {
        this.$emit("input", this.selected);
      },
      deep: true,
      immediate: false,
    },
  },
  created() {
    this.makeQuery();
  },
  computed: {
    tableData() {
      return this.mxFilteredItems.map((item) => {
        return { ...item };
      });
    },
    isEmpty(){
        return this.mxFilteredItems.length == 0
    },
    referentielCaptilizedName() {
      let lowerName = this.referentielName;
      return lowerName[0].toUpperCase() + lowerName.slice(1, lowerName.length);
    },
    referentielLowercaseName() {
      return this.referentielName; //Not use toLowerCase
    },
  },
  methods: {
    makeQuery() {
      this.mxInitializePaginator({
        queryModel: "participationPlacement",
        search: this.search,
        fetcherMethod:
          "participationPlacement/fetchParticipationPlacementMesEmployersWithPayments",
        dataGetter: "participationPlacement/participationPlacements",
        paginationGetter: "participationPlacement/pagination",
        autoSubmitQuery: false,
        pagination: {
          sortBy: "nom",
          sortDesc: false,
          descending: false,
          page: 0,
          rowsPerPage: 10,
        },
        filterOptions: this.filterOptions,
        searchFields: this.searchFields,
        extraQueryArgsParamBuilder:
          this.extraQueryArgsParamBuilder == null
            ? () => {
                const dateDeb = (this.dateDeb || new Date("2021-12-31"))
                  .toISOString()
                  .substring(0, 10);
                const dateFin = (this.dateFin || new Date("2080-12-31"))
                  .toISOString()
                  .substring(0, 10);
                let params = `dateDeb=${dateDeb}&dateFin=${dateFin}&${this.extraQueryArg}`;
                if(this.statutPayement != null){
                  params += `statutPayement=${this.statutPayement}`
                }
                return params;
              }
              : () => {

                let params = this.extraQueryArgsParamBuilder()
                if(this.statutPayement != null){
                  params += `&statutPayement=${this.statutPayement}`
                }
                return params;
            }
      });
    },
    reloadData(){
        this.makeQuery()
    },
    getStatutPayementLabel(item) {
      return item.statutPayement == null ? "VIDE" : item.statutPayement;
    },
    getStatutPayementColor(item) {
      return item.statutPayement == null ? "danger" : item.statutPayement == 'PAYE' ? "success" : "primary";
    },
    clearSelection() {
      this.saved = [];
      this.selected = [];
      this.$refs.table.clearSelected();
    },
  },
};
</script>

<style></style>
